<template>
  <div class="context">
    <div class="group">
      DATA:
      <InventionPicker v-model="localContext.invention" class="variable" @input="onChange" />
      <DocumentPicker v-model="localContext.document" class="variable" @input="onChange" />
    </div>
    <ul v-if="previousRun.forms && previousRun.forms.length" class="group">
      FORMS:
      <li v-for="variable of previousRun.forms" :key="variable.name" class="variable">
        <ImageVariable
          v-if="variable.type === 'image'"
          v-model="variable.value"
          :label="`${variable.form.toUpperCase()}: ${variable.label ? `${variable.label} (${variable.name})` : variable.name}`"
          :preview="false"
        />
        <ClaimsVariable v-else-if="variable.type === 'claims'" v-model="variable.value" :context="localContext" :item="variable" />
        <BooleanVariable v-else-if="variable.type === 'boolean'" v-model="variable.value" :label="variable.label || variable.name" />
        <TextVariable
          v-else
          v-model="variable.value"
          :label="`${variable.form.toUpperCase()}: ${variable.label ? `${variable.label} (${variable.name})` : variable.name}`"
          :multiline="true"
        />
      </li>
    </ul>
    <ul v-if="previousRun.output && previousRun.output.length" class="group">
      OUTPUT:
      <li v-for="variable of previousRun.output" :key="variable.name" class="variable">
        <TextField v-model="variable.value" :label="variable.name" :multiline="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import InventionPicker from '@/components/common/InventionPicker';
import DocumentPicker from '@/components/common/DocumentPicker';
import TextField from '@/components/common/TextField';
import ImageVariable from './variables/ImageVariable';
import TextVariable from './variables/TextVariable';
import ClaimsVariable from './variables/ClaimsVariable.vue';
import BooleanVariable from './variables/BooleanVariable.vue';

export default {
  components: {
    InventionPicker,
    DocumentPicker,
    TextField,
    ImageVariable,
    TextVariable,
    ClaimsVariable,
    BooleanVariable
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      localContext: { ...this.value }
    };
  },
  computed: {
    ...mapState({
      previousRun: s => s.prompts.sample.previousRun
    })
  },
  methods: {
    onChange() {
      this.$emit('input', { ...this.localContext });
    }
  }
};
</script>

<style lang="scss" scoped>
.context {
  overflow-y: auto;
  height: 100%;
  padding-right: 5px;

  .variable {
    padding: 5px;
    padding-left: 0;
  }

  .group {
    border: 1px solid;
    margin-bottom: 7px;
    padding: 5px;
    background-color: var(--theme-surface);
  }
}
</style>

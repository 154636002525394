<template>
  <section v-if="!!item" class="prompt-content">
    <Settings v-if="showSettings" :item="item" @cancel="$emit('cancelEditingSettings')" @submit="$e => $emit('submit', $e)" />
    <template v-else>
      <div class="left">
        <div class="header">
          <div class="header-buttons">
            <div class="import-export">
              <h3>{{ item.name }}</h3>
              <Button class="button" @click="exportPrompt">
                <md-icon name="export" />
              </Button>
              <Button class="button" @click="importModalVisible = true">
                <md-icon name="import" />
              </Button>
            </div>
            <div></div>
            <div>
              <Button
                v-if="$hasPermission('prompts.write') && hasChanges"
                variant="text"
                color="primary"
                :disabled="isUpdateRequestPending"
                @click="save"
              >
                <md-icon name="content-save" />
              </Button>
              <Button v-if="!isPromptStreaming" class="button" title="Execute chain" @click="exec">
                <md-icon name="fast-forward" />
              </Button>
              <Button v-else title="Stop execution" class="button" @click="cancel">
                <md-icon name="stop" class="red" />
              </Button>
            </div>
          </div>
          <div class="add-buttons">
            <Button color="primary" :disabled="isPromptStreaming" @click="addPrompt">+ Prompt</Button>
            <Button color="primary" :disabled="isPromptStreaming" @click="addQuery">+ Query</Button>
            <Button color="primary" :disabled="isPromptStreaming" @click="addTemplate">+ Template</Button>
            <Button color="primary" :disabled="isPromptStreaming" @click="addForm">+ Form</Button>
            <Button v-if="!hasChat" color="secondary" :disabled="isPromptStreaming" @click="addChat">+ Chat</Button>
          </div>
        </div>

        <div class="body">
          <TransitionGroup name="list" tag="ul">
            <li v-for="(link, index) in chain" :key="link.key">
              <PromptItem
                :item="link"
                :chain="chain"
                :index="index"
                :index-in-group="chain.filter(l => l.type === link.type).indexOf(link)"
                :first="index == 0"
                :context="context"
                :last="index == chain.length - 1"
                @change="onChange($event, index)"
                @delete="onDelete(index)"
                @execute="onExecuteOne(index)"
                @up="onMoveUp(index)"
                @down="onMoveDown(index)"
              />
            </li>
          </TransitionGroup>
          <ChatSettings v-if="hasChat" :settings="chat" @change="onChatChange($event)" @delete="onDeleteChat()" />
        </div>
      </div>

      <div class="middle"></div>
      <div class="right">
        <div class="content">
          <div class="tabs">
            <Button variant="text" class="tab" :class="{ active: dialogTabActive }" @click="currentTab = 'dialog'">Dialog</Button>
            <Button v-if="!isPromptStreaming" class="tab" variant="text" :class="{ active: contextTabActive }" @click="currentTab = 'context'"
              >Context
            </Button>
          </div>
          <div class="tab-content">
            <div v-if="dialogTabActive" class="output-container">
              <Output :context="context" @play="exec" />
            </div>
            <div v-if="hasChat && dialogTabActive" class="chat-input">
              <textarea
                ref="chatTextarea"
                v-model="chatMessage"
                :disabled="isPromptStreaming"
                placeholder="Type a message..."
                @keydown.enter.prevent="sendMessage"
              ></textarea>
              <Button class="send-button" :disabled="isPromptStreaming" @click="sendMessage">Send</Button>
            </div>
            <Context v-else-if="contextTabActive" v-model="context" />
          </div>
        </div>
      </div>
    </template>
    <ImportPromptModal v-if="importModalVisible" @import="doImport" @cancel="importModalVisible = false" />
  </section>
</template>

<script>
import { mapState } from 'vuex';

import ImportPromptModal from './ImportPromptModal.vue';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import Output from './Output.vue';
import PromptItem from './PromptItem';
import ChatSettings from './ChatSettings.vue';
import Context from './Context.vue';
import Settings from './_CreateOrEdit.vue';

function deepCloneArray(arr) {
  if (!Array.isArray(arr)) {
    throw new Error('Input is not an array');
  }

  return arr.map(item => {
    if (Array.isArray(item)) {
      return deepCloneArray(item);
    } else if (typeof item === 'object' && item !== null) {
      return deepCloneObject(item);
    } else {
      return item;
    }
  });
}

function deepCloneObject(obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  const clone = {};

  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (Array.isArray(obj[key])) {
        clone[key] = deepCloneArray(obj[key]);
      } else if (typeof obj[key] === 'object' && obj[key] !== null) {
        clone[key] = deepCloneObject(obj[key]);
      } else {
        clone[key] = obj[key];
      }
    }
  }

  return clone;
}

export default {
  components: {
    PromptItem,
    Output,
    Button,
    MdIcon,
    ImportPromptModal,
    Context,
    ChatSettings,
    Settings
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    showSettings: {
      type: Boolean,
      default: false
    }
  },

  data() {
    const localChain = this.item.template?.chain ? JSON.parse(JSON.stringify(this.item.template.chain)) : [];
    localChain.forEach(link => (link.type = link.type ?? 'prompt'));
    localChain.forEach((link, index) => (link.key = `${link.type}_${link.name}_${index}`));

    return {
      chain: localChain ?? [],
      importModalVisible: false,
      memo: {
        chain: JSON.parse(JSON.stringify(localChain)),
        chat: this.item.template.chat ? JSON.parse(JSON.stringify(this.item.template.chat)) : null
      },
      context: {
        invention: null,
        document: null
      },
      currentTab: 'dialog',
      chatMessage: '',
      chat: this.item.template.chat || null,
      shouldFocus: false
    };
  },
  computed: {
    ...mapState({
      isUpdateRequestPending: s => s.prompts.isUpdateRequestPending,
      isRequestPending: s => s.prompts.sample.isRequestPending,
      isRequestFailed: s => s.prompts.sample.isRequestFailed,
      models: s => s.nlp.models || [],
      providers: s => s.nlp.providers || [],
      isPromptStreaming: s => s.prompts.sample.isRequestPending
    }),
    dialogTabActive() {
      return this.currentTab === 'dialog';
    },
    contextTabActive() {
      return this.currentTab === 'context';
    },
    requiresInvention() {
      return this.chain.find(l => l.type === 'query' || l.type === 'template');
    },
    requiresContext() {
      return this.chain.find(l => l.type === 'template' || l.type === 'query');
    },
    hasChanges() {
      return JSON.stringify(this.chain) !== JSON.stringify(this.memo.chain) || JSON.stringify(this.chat) !== JSON.stringify(this.memo.chat);
    },
    hasChat() {
      return !!this.chat;
    }
  },
  watch: {
    chain: {
      handler(newValue, oldValue) {
        if (this.chain.length === 0) {
          this.chat = null;
        }
      },
      deep: true
    }
  },
  async created() {
    await Promise.all([
      this.$store.dispatch('prompts/sample/reset', this.chain),
      this.$store.dispatch('prompts/sample/getDocumentation'),
      this.$store.dispatch('nlp/getConfigurations')
    ]);
  },
  mounted() {
    document.addEventListener('keydown', this.doSave);
  },
  async beforeDestroy() {
    document.removeEventListener('keydown', this.doSave);
    this.$store.dispatch('prompts/sample/closeStream');
  },
  methods: {
    doSave(e) {
      if (!(e.keyCode === 83 && e.ctrlKey)) {
        return;
      }

      e.preventDefault();
      this.save();
    },
    async sendMessage() {
      if (!this.chatMessage.trim()) {
        return;
      }

      if (!this.chat) {
        return;
      }

      this.shouldFocus = true;
      const systemMessage = this.chat.messages.filter(message => message.role !== 'user');

      const chatPayload = {
        ...this.chat,
        messages: [
          ...systemMessage,
          {
            role: 'user',
            content: this.chatMessage
          }
        ]
      };

      try {
        this.chatMessage = '';
        await this.$store.dispatch('prompts/sample/sendChatMessage', chatPayload);
      } catch (error) {
        this.$toast.error({
          title: 'Error sending message',
          message: 'There was an error sending your message. Please try again.'
        });
      } finally {
        this.$nextTick(() => {
          if (this.shouldFocus) {
            this.$refs.chatTextarea.focus();
            this.shouldFocus = false;
          }
        });
      }
    },
    getOutputLabel(prompt) {
      var index = this.chain.filter(c => c.type === prompt.type).indexOf(prompt) + 1;
      return `${prompt.type.toUpperCase()}_${index}_OUTPUT`;
    },
    getFileLabel(variable) {
      return variable.label || variable.name;
    },
    getLinkModel(candidate) {
      const models = this.models;
      const model = models.find(m => m.id == candidate.model);
      if (model) {
        return model;
      }

      for (const md of models) {
        if (!md.config) {
          continue;
        }
        var config = JSON.parse(md.config);
        if (config.deployment == candidate.model) {
          return md;
        }
      }

      return null;
    },
    async save() {
      if (!this.hasChanges) {
        return;
      }

      const chain = this.chain.map(link => JSON.parse(JSON.stringify(link)));
      chain.forEach(link => delete link['key']);
      try {
        await this.$emit('submit', {
          id: this.item.id,
          ...this.item,
          template: {
            model: this.model,
            max_tokens: this.max_tokens,
            temperature: this.temperature,
            chain,
            ...(this.chat && { chat: this.chat })
          }
        });
        this.$toast.success({
          title: 'Update completed',
          message: `Prompt was updated.`
        });
        this.memo = {
          chain: JSON.parse(JSON.stringify(this.chain)),
          chat: this.chat ? JSON.parse(JSON.stringify(this.chat)) : null
        };
      } catch (e) {
        this.$toast.error({
          title: 'Update failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    doImport(templateString) {
      this.importModalVisible = false;
      try {
        const template = JSON.parse(templateString);
        template.chain.filter(l => !l.type).forEach(l => (l.type = 'prompt'));
        template.chain.forEach((link, index) => (link.key = `${link.type}_${link.name}_${index}`));
        this.chain = template.chain || [];
        this.chat = template.chat;
      } catch (error) {
        this.$toast.error({
          title: 'Invalid import string'
        });
      }
    },
    async cancel() {
      await this.$store.dispatch('prompts/sample/closeStream');
    },
    async exec() {
      this.currentTab = 'dialog';
      try {
        await this.$store.dispatch('prompts/sample/reset', this.chain);
        await this.$store.dispatch('prompts/sample/exec', {
          chain: this.chain,
          variables: [],
          context: {
            invention: this.context.invention,
            ...(this.context.document || {})
          },
          debug: true
        });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to generate sample',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async exportPrompt() {
      await navigator.clipboard.writeText(
        JSON.stringify({
          model: this.model,
          max_tokens: this.max_tokens,
          temperature: this.temperature,
          chain: this.chain,
          chat: this.chat
        })
      );
      this.$toast.success({
        message: `Template is copied to clipboard`
      });
    },
    async onExecuteOne(index) {
      this.currentTab = 'dialog';

      try {
        const link = this.chain[index];
        if (link.type === 'form') {
          link.continue = false;
        }

        await this.$store.dispatch('prompts/sample/exec', {
          execOne: true,
          index: this.chain.filter(l => l.type !== 'form').indexOf(link),
          chain: [link],
          variables: [],
          context: {
            invention: this.context.invention,
            ...(this.context.document || {})
          }
        });
      } catch (e) {
        this.$toast.error({
          title: 'Failed to generate sample',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    onChange(item, index) {
      this.chain.splice(index, 1, { ...item });
    },
    onChatChange(item) {
      this.chat = { ...item };
    },
    async onDelete(index) {
      const link = this.chain[index];
      if (
        !(await this.$confirm({
          title: 'Delete Link',
          message: `Are you sure you want to delete "${link.name}" ?`,
          confirm: 'Delete'
        }))
      ) {
        return;
      }

      this.chain.splice(index, 1);
    },
    async onDeleteChat() {
      this.chat = null;
    },
    onMoveUp(index) {
      if (index > 0) {
        this.chain.splice(index - 1, 0, this.chain.splice(index, 1)[0]);
      }
    },
    onMoveDown(index) {
      if (index < this.chain.length - 1) {
        const itemToMove = this.chain.splice(index, 1)[0];
        this.chain.splice(index + 1, 0, itemToMove);
      }
    },
    addPrompt() {
      this.chain.push({
        type: 'prompt',
        model: this.models[0]?.id,
        name: `PROMPT ${this.chain.filter(l => l.type === 'prompt').length + 1}`,
        key: `PROMPT_${this.chain.filter(l => l.type === 'prompt').length + 1}`,
        messages: [
          {
            role: 'system',
            content: 'You are a patent attorney who always answers correctly.'
          },
          {
            role: 'user',
            content: 'Who are you?'
          }
        ],
        max_tokens: null,
        temperature: 0.7
      });
    },
    addQuery() {
      this.chain.push({
        type: 'query',
        name: `QUERY ${this.chain.filter(l => l.type === 'query').length + 1}`,
        key: `QUERY_${this.chain.filter(l => l.type === 'query').length + 1}`,
        query: `SELECT text_chunk
FROM paragraphs
WHERE refs && ARRAY[{invention.references}] 
ORDER BY embedding <-> 'TEXT TO SEARCH' 
LIMIT 3`
      });
    },
    addTemplate() {
      this.chain.push({
        type: 'template',
        name: `TEMPLATE ${this.chain.filter(l => l.type === 'template').length + 1}`,
        key: `TEMPLATE_${this.chain.filter(l => l.type === 'template').length + 1}`,
        template: '',
        renderOnly: false
      });
    },
    addForm() {
      this.chain.push({
        type: 'form',
        name: `FORM ${this.chain.filter(l => l.type === 'form').length + 1}`,
        key: `FORM_${this.chain.filter(l => l.type === 'form').length + 1}`,
        variables: []
      });
    },
    addChat() {
      this.chat = {
        type: 'chat',
        model: this.models[0]?.id,
        name: `CHAT`,
        key: `CHAT`,
        messages: [
          {
            role: 'system',
            content: 'You are a patent attorney who always answers correctly.'
          }
        ],
        max_tokens: null,
        temperature: 0.7,
        includeHistory: true
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid var(--theme-on-surface);

  textarea {
    flex: 1;
    padding: 10px;
    border: 1px solid var(--theme-on-surface);
    border-radius: 4px;
    margin-right: 10px;
    resize: none;
    font-size: 14px;
  }

  .send-button {
    padding: 10px;
    background-color: var(--theme-primary);
    color: var(--theme-on-primary);
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
}

.settings {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.3s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(20px);
}
.list-leave-active {
  position: absolute;
}

.prompt-content {
  h3 {
    text-transform: uppercase;
    overflow: hidden;
    font-weight: 500;
    padding: 5px;
    display: inline-block;
  }

  .button {
    padding: 0;
    cursor: pointer;
    :hover {
      background-color: var(--theme-on-surface);
      color: var(--theme-surface);
      border-radius: 2px;
    }

    .red {
      color: red;
    }

    i {
      width: 25px;
      height: 25px;
      font-size: 21px;
    }
  }

  width: 100%;
  height: 100%;
  margin: 0 0.5rem;
  overflow: scroll;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 1px minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  grid-gap: 0.5rem;

  .left {
    height: 100%;
    width: 100%;
    position: relative;
    overflow-y: hidden;
    display: flex;

    .header {
      position: absolute;
      top: 0;
      width: 100%;

      .header-buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--theme-on-surface);

        .import-export {
          display: flex;
          grid-gap: 5px;
          align-items: center;
        }
      }

      .add-buttons {
        display: grid;
        grid-template-columns: max-content max-content max-content max-content max-content 1fr;
        padding-top: 5px;
        padding-bottom: 5px;
        gap: 5px;
        width: 100%;
        border-bottom: 1px solid var(--theme-on-surface);
      }
    }

    .body {
      position: absolute;
      width: 100%;
      top: 90px;
      bottom: 5px;
      overflow-y: scroll;
    }
  }

  .middle {
    border-right: 2px solid var(--theme-on-surface);
    opacity: 0.5;
    height: 100%;
  }

  .right {
    height: 100%;
    overflow-y: hidden;

    .content {
      width: 100%;
      height: 100%;
      overflow-y: hidden;
      top: 0px;
      bottom: 5px;

      .tabs {
        border-bottom: 1px solid white;

        .tab {
          border-radius: 0px;
          border-top-left-radius: 3px;
          border-top-right-radius: 3px;
          padding: 10px;
          &.active {
            background-color: white;
            color: black;
          }
        }
      }

      .tab-content {
        padding-top: 5px;
        overflow-y: hidden;
        height: 94%;
        display: grid;
        grid-template-rows: 1fr auto;
        height: 95%;
      }

      .output-container {
        overflow-y: auto;
      }
    }
  }

  .prompt-content-wrapper,
  .sample-input-wrapper {
    display: grid;
    grid-template-rows: max-content minmax(0, 1fr);
    grid-gap: 0.5rem;

    .variables-header {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 1px solid var(--theme-on-surface);
    }
  }

  .prompt-content-title-wrapper,
  .sample-input-title-wrapper {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    justify-content: baseline;
    height: 20px;

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>

<template>
  <div class="form-content">
    <div v-if="valuesOnly">
      <div v-for="(variable, index) in localItem.variables" :key="index" class="values-row">
        <ImageVariable
          v-if="variable.type === 'image'"
          v-model="variable.value"
          :label="variable.label || variable.name"
          :compact="false"
          :preview="false"
          :disabled="readOnly"
          @input="onChange"
        />
        <ClaimsVariable
          v-else-if="variable.type === 'claims'"
          v-model="variable.value"
          :context="currentContext"
          :item="variable"
          :disabled="readOnly"
          @input="onChange"
        />
        <BooleanVariable
          v-else-if="variable.type === 'boolean'"
          v-model="variable.value"
          :label="variable.label || variable.name"
          :disabled="readOnly"
          @input="onChange"
        />
        <TextVariable
          v-else
          v-model="variable.value"
          :label="variable.label || variable.name"
          :disabled="readOnly"
          :multiline="true"
          @input="onChange"
        />
      </div>
    </div>
    <div v-else>
      <div class="button">
        <Button color="primary" :disabled="isPromptStreaming" @click="addVariable">+ Variable</Button>
      </div>
      <div v-for="(variable, index) in localItem.variables" :key="index" class="row">
        <TextField v-model="variable.name" label="Name" :disabled="isPromptStreaming" @input="onChange" />
        <TextField v-model="variable.label" label="Label" :disabled="isPromptStreaming" @input="onChange" />
        <Multiselect v-model="variable.type" label="Type" :disabled="isPromptStreaming" :options="types" @input="onChange" />
        <div>
          <ImageVariable
            v-if="variable.type === 'image'"
            v-model="variable.value"
            :label="'Default Value'"
            :compact="true"
            :disabled="isPromptStreaming"
            @input="onChange"
          />
          <Checkbox
            v-else-if="variable.type === 'boolean'"
            v-model="variable.value"
            label="Default values"
            class="boolean"
            :disabled="isPromptStreaming"
            @input="onChange"
          />
          <div v-else-if="variable.type === 'claims'" class="claims">
            <Checkbox v-model="variable.independentOnly" label="Independent claims only" :disabled="isPromptStreaming" @input="onChange" />
            <Checkbox v-model="variable.singleClaimMode" label="Single claim selection" :disabled="isPromptStreaming" @input="onChange" />
            <div class="default-value">
              <Checkbox
                v-model="variable.defaultValue"
                label="Default value selector"
                :disabled="isPromptStreaming"
                @change="e => onVariableDefaultValue(variable, e)"
              />
              <div v-if="variable.defaultValue" class="options">
                <Multiselect
                  v-model="variable.selectorWhich"
                  label="Type"
                  :searchable="false"
                  :disabled="isPromptStreaming"
                  :options="getOptions(variable, 'which')"
                />
                <Multiselect
                  v-if="variable.selectorWhich"
                  v-model="variable.selectorProperty"
                  label="Property"
                  :searchable="false"
                  :disabled="isPromptStreaming"
                  :options="getOptions(variable, 'property')"
                />
                <Multiselect
                  v-if="variable.selectorProperty"
                  v-model="variable.selectorValue"
                  label="Value"
                  :searchable="false"
                  :disabled="isPromptStreaming"
                  :options="getOptions(variable, variable.selectorProperty)"
                  @input="onChange"
                />
              </div>
            </div>
          </div>
          <TextVariable v-else v-model="variable.value" :label="'Default Value'" :disabled="isPromptStreaming" @input="onChange" />
        </div>

        <Button class="remove-button" title="Remove variable" :disabled="isPromptStreaming" @click="removeVariable(index)">
          <MdIcon name="close" />
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TextField from '@/components/common/TextField';
import Multiselect from '@/components/common/Multiselect';
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
import ImageVariable from '../variables/ImageVariable';
import TextVariable from '../variables/TextVariable';
import ClaimsVariable from '../variables/ClaimsVariable.vue';
import BooleanVariable from '../variables/BooleanVariable.vue';
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    TextField,
    Multiselect,
    Checkbox,
    Button,
    MdIcon,
    ImageVariable,
    ClaimsVariable,
    TextVariable,
    BooleanVariable
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    context: {
      type: Object,
      default: () => {}
    },
    suggestions: {
      type: Array,
      default: () => []
    },
    prefix: {
      type: String,
      default: ''
    },
    valuesOnly: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const localItem = {
      ...this.item,
      variables: this.item.variables.map(v => ({ ...v }))
    };

    for (const variable of localItem.variables) {
      if (variable.defaultValueSelector) {
        variable.defaultValue = true;
        variable.selectorWhich = variable.defaultValueSelector.which;
        variable.selectorProperty = variable.defaultValueSelector.property;
        variable.selectorValue = variable.defaultValueSelector.value;
      }
    }

    return {
      currentContext: this.context,
      localItem
    };
  },
  computed: {
    ...mapState({
      isPromptStreaming: s => s.prompts.sample.isRequestPending,
      providers: s => s.nlp.providers || [],
      variableSelectors: s => s.nlp.variableSelectors || {}
    }),
    types() {
      const types = [];
      const allCapabilites = [];
      this.providers?.map(p => p.capabilities).forEach(pc => allCapabilites.push(...pc));

      for (const capability of allCapabilites) {
        types.push(...(capability?.variables || []));
      }

      return [...new Set(types)];
    }
  },
  watch: {
    'localItem.variables': {
      handler(newVal, oldVal) {
        for (const oldEl of oldVal) {
          const newEl = newVal.find(element => element.name === oldEl.name);
          if (!newEl) {
            break;
          }

          if (newEl.type !== oldEl.type) {
            newEl.value = newEl.type === 'image' ? [] : '';
            break;
          }
        }
      },
      deep: true // Enable deep watching
    }
  },
  methods: {
    onVariableDefaultValue(variable, enabled) {
      variable.defaultValue = enabled;
      this.onChange();
    },
    getOptions(variable, value) {
      try {
        var selector = this.variableSelectors[variable.type];

        if (!selector) {
          return [];
        }

        var selectorValue = selector[value];

        if (Array.isArray(selectorValue)) {
          return selectorValue;
        }

        return Object.getOwnPropertyNames(selectorValue);
      } catch (error) {
        return [];
      }
    },
    addVariable() {
      let index = 1;
      let varName = `${this.prefix}_Var_${index}`;
      while (this.localItem.variables.find(v => v.name === varName)) {
        index++;
        varName = `${this.prefix}_Var_${index}`;
      }

      this.localItem.variables.push({
        name: varName,
        label: varName,
        type: 'string',
        value: ''
      });
      this.onChange();
    },
    removeVariable(index) {
      this.localItem.variables.splice(index, 1);
      this.onChange();
    },
    onChange() {
      const updated = {
        ...this.localItem,
        variables: this.localItem.variables.map(v => ({ ...v, defaultValueSelector: undefined }))
      };

      for (const variable of updated.variables) {
        if (variable.defaultValue) {
          variable.defaultValueSelector = { which: variable.selectorWhich, property: variable.selectorProperty, value: variable.selectorValue };
        } else {
          delete variable.defaultValueSelector;
        }

        delete variable.defaultValue;
        delete variable.selectorWhich;
        delete variable.selectorProperty;
        delete variable.selectorValue;
      }

      this.$emit('change', updated);
    }
  }
};
</script>

<style scoped lang="scss">
.form-content {
  overflow-y: none;
  .button {
    margin-bottom: 10px;
  }

  .values-row {
    overflow-y: hidden;
    margin-bottom: 10px;
    grid-gap: 5px;
    align-items: flex-start;
    height: 100%;

    label {
      word-wrap: break-word;
    }
  }

  .row {
    display: grid;
    grid-template-columns: 100px 100px 100px 1fr 10px;
    margin-bottom: 10px;
    grid-gap: 5px;
    align-items: flex-start;

    .boolean {
      padding: 10px;
    }

    .claims {
      display: grid;
      grid-gap: 5px;
      align-self: center;
      padding: 10px;

      .default-value {
        display: grid;
        gap: 5px;

        .options {
          display: grid;
          gap: 5px;
          grid-template-columns: 1fr 1fr 1fr;
        }
      }
    }

    .remove-button {
      align-self: center;
    }
  }
}
</style>

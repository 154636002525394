<template>
  <div class="root">
    <Checkbox v-model="localValue" :disabled="disabled" :label="label" @change="onChange" />
  </div>
</template>
<script>
import Checkbox from '@/components/common/Checkbox';

export default {
  components: {
    Checkbox
  },
  props: {
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    label: {
      type: String,
      required: false,
      default: 'Boolean value'
    }
  },
  data() {
    return {
      localValue: this.value
    };
  },
  methods: {
    onChange() {
      this.$emit('input', this.localValue);
    }
  }
};
</script>
<style lang="scss" scoped>
.root {
  display: grid;
}
</style>
